import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import { SearchBar } from '../SearchBar';
import { Schedules } from './Schedules';
import { strings } from '../../resources/strings';
import { PlanRouteCommand } from '../commands/PlanRouteCommand';
import { Disclaimer } from '../Disclaimer';

export class SchedulesByStop extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "schedulesByStop";

        this.state = {
            commands: {
                planRoute: new PlanRouteCommand()
            },
            lines: [],
            lineSelected: undefined,
            origin: undefined,
            destination: undefined,
            displayResults: false,
            displaySchedules: false
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined !== state) {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;

        setState(this.stateKey, this.state);
    }

    //DATA
    checkLines(e) {
        e.preventDefault();
        this.setState({
            selectedLine: undefined,
            displaySchedules: false
        });

        const { commands, origin, destination } = this.state;
        if (undefined !== origin && destination !== undefined) {
            commands.planRoute.getLinesByOriginAndDestination(origin.id, destination.id, (r) => this.checkLinesSuccessCallback(r));
        }
    }

    checkLinesSuccessCallback(result) {
        this.setState({
            lines: result.sort((a, b) => a.code.localeCompare(b.code)),
            displayResults: true
        });
    }

    //---------

    //FUNCTIONS
    handleStartStopSelection(stop) {
        this.setState({
            origin: stop
        });
    }

    handleStartSearchBarClear() {
        this.setState({
            origin: undefined
        });
    }

    handleEndStopSelection(stop) {
        this.setState({
            destination: stop
        });
    }

    handleEndSearchBarClear() {
        this.setState({
            destination: undefined
        });
    }

    handleLineSelection(line) {
        /*
        * This is done to force react to re-render the Schedules component when another line is selected.
        * Using some prop as a component key in Schedules isn't working but did work on LineInfoMap.isPermanent().
        * So there may be a better way of doing this */
        
        this.setState({
            displaySchedules: false
        }, () => {
            this.setState({
                lineSelected: line,
                displaySchedules: true
            });
        });
    }
    //---------------

    //RENDER
    renderSearchPanel() {
        return (
            <form id="stops_form" ref="stops_form" className="schedules-by-stop-form-panel" onSubmit={(e) => this.checkLines(e)}>
                <div className="schedules-by-stop-search-bar-panel">
                    <SearchBar
                        className="schedules-by-stop-search-bar"
                        resultsClassName="schedules-by-stop-search-bar-results-list"
                        caller="schedulebystop-startstop"
                        placeholder={strings.originStopPlaceHolder}
                        onSelect={(stop) => this.handleStartStopSelection(stop)}
                        onSearchClear={() => this.handleStartSearchBarClear()}
                        isRequired={true} />

                    <SearchBar
                        className="schedules-by-stop-search-bar"
                        resultsClassName="schedules-by-stop-search-bar-results-list"
                        caller="schedulebystop-endstop"
                        placeholder={strings.destinationStopPlaceHolder}
                        onSelect={(stop) => this.handleEndStopSelection(stop)}
                        onSearchClear={() => this.handleEndSearchBarClear()}
                        isRequired={true} />
                </div>

                <div className="schedules-by-stop-check-lines-button-panel">
                    <Button id="checklines_button" className="schedules-by-stop-check-lines-button" type="submit">{strings.checkSchedules}</Button>
                </div>
            </form >
        );
    }

    renderLines() {
        const { lines, displayResults } = this.state;

        if (!displayResults) {
            return null;
        }

        return (
            <div className="schedules-by-stop-lines-panel">
                <div className="schedules-by-stop-lines-panel-header d-flex justify-content-between">
                    <div className="d-flex-inline text-truncate schedules-by-stop-lines-panel-header-info">
                        <b>{strings.availableLines}</b>
                    </div>
                </div>

                <ListGroup className="lines-list">
                    {this.renderLinesInfo(lines)}
                </ListGroup>

            </div>
        );
    }

    renderLinesInfo(lines) {
        if (0 !== lines.length) {
            return (
                lines.map((line, index) =>
                    <ListGroupItem key={`line-${index}`}>
                        <Row>
                            <Col xs={8} sm={8} className="schedules-by-stop-lines-list-line-header-info">
                                <div className="d-flex-inline text-truncate">
                                    <b>{line.code}</b>
                                    &nbsp;
                                    {line.name}
                                </div>
                            </Col>
                            <Col xs={4} sm={4} className="schedules-by-stop-lines-list-line-header-show-schedule">
                                <span className="orange-text pointer-cursor" onClick={() => this.handleLineSelection(line)}>{strings.showSchedule}</span>
                            </Col>
                        </Row>
                    </ListGroupItem >
                )
            );
        } else {
            return (
                <ListGroupItem key={`trip-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noInfoToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }
    }

    renderSchedules() {
        const { lineSelected, displaySchedules } = this.state;

        if (displaySchedules) {
            return (
                <div className="schedules-by-stop-schedules-panel">
                    <Schedules lineId={lineSelected.id} direction={lineSelected.direction} displayLineDropdown={false} />
                </div>
            );
        }

        return null;
    }

    render() {
        const isMenuOpen = this.context.getMenuState();
        return (
            <div className="schedules-by-stop-panel">
                {this.renderSearchPanel()}
                {this.renderLines()}
                {this.renderSchedules()}
                <Disclaimer marginClass={isMenuOpen ? "margin-left-250" : "margin-left-50"} />
            </div>
        );
    }
}
