import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { TripCommand } from '../commands/TripCommand';
import { Container, Col, Row, Button, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { LineInfoMap } from './LineInfoMap';


export class LineInfo extends Component {
    static contextTypes = {
        getLogo: PropTypes.func,
        getMenuState: PropTypes.func,
        setMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            commands: {
                trips: new TripCommand()
            },
            trip: undefined,
            selectedTripPassing: undefined,
            realTimePassing: undefined,
            isLoadingTrip: true,
            displayTripInfo: true,
            displayMap: false,
            minuteDelay: 0
        }
    }

    componentDidMount() {
        //Set function to handle when window is resized
        window.onresize = () => this.resize();

        this.getTrip();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    resize() {
        if (window.innerWidth > 767) {
            this.setState({
                displayTripInfo: true,
                displayMap: true
            });
        } else {
            if (this.context.getMenuState()) {
                this.context.setMenuState();
            }
            document.getElementById("line-info-show-stops-button").click();
        }
    }

    //TRIP
    getTrip() {
        const { commands } = this.state;
        const { passing } = this.props;

        if (undefined !== passing) {
            commands.trips.getTrip(passing.tripId, (r) => this.getTripSuccessCallback(r));
        }
    }

    getTripSuccessCallback(result) {
        const { passing } = this.props;

        let selectedPassing = result.passings.find(p => p.stopCode === passing.stopCode && p.order === passing.order && p.isRT);
        if (selectedPassing !== undefined) {
            let now = new Date(Date.now());
            let scheduleTime = new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() + selectedPassing.timeStamp * 60000);
            let calculatedTime = new Date(now.getTime() + selectedPassing.duration * 60000);
            var diff = selectedPassing.isDeterministic ? parseInt((calculatedTime.getTime() - scheduleTime.getTime()) / 60000) : 0;
        } else {
            selectedPassing = result.passings.find(p => p.isRT && p.order > passing.order);
        }

        this.setState({
            trip: result,
            realTimePassing: selectedPassing !== undefined ? selectedPassing : undefined,
            isLoadingTrip: false,
            displayMap: true,
            minuteDelay: diff
        }, () => {
            //call resize function to check the current width, otherwise resizing the window is the only trigger;  
            this.resize();
        });
    }

    //HELPER FUNCTIONS
    handleTripRefresh() {
        this.setState({
            isLoadingTrip: true,
            displayMap: false //Redraw LineInfoMap component in order to start updating the bus position in case the trip becomes realtime
        }, () => {
            this.getTrip()
        });
    }

    handleSelectTripPassing(tripPassing) {
        this.setState({
            selectedTripPassing: tripPassing
        });
    }

    handleMoreSchedsClick() {
        const { trip } = this.state;
        const { passing, provider, onMoreSchedsClick } = this.props;
        onMoreSchedsClick({ trip: trip, passing: passing, provider: provider });
    }

    displayTripInfo() {
        this.setState({
            displayTripInfo: true,
            displayMap: false
        });
    }

    displayMap() {
        this.setState({
            displayTripInfo: false,
            displayMap: true
        });
    }

    formatDuration(duration) {
        if (duration < 60) {
            return `${duration}min`;
        }

        let h = Math.floor(duration / 60);
        let m = duration % 60;
        m = m < 10 ? '0' + m : m;
        return `${h}h ${m}min`;
    }

    //--------------

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="40px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderInfo() {
        const { trip, selectedTripPassing, realTimePassing, displayTripInfo, displayMap } = this.state;
        const { passing, provider } = this.props;

        if (displayTripInfo && displayMap) {
            return (
                <Row>
                    <Col sm={5} className="line-info-container-trip-col">
                        {this.renderTripHeader(trip)}
                        {this.renderTrip(trip)}
                    </Col>
                    <Col sm={7} className="line-info-container-map-col">
                        <LineInfoMap trip={trip} passing={passing} realTimePassing={realTimePassing} selectedTripPassing={selectedTripPassing} provider={provider} makeTooltipsPermanent={true} />
                    </Col>
                </Row>
            );
        } else {
            if (displayTripInfo) {
                return (
                    <Row>
                        <Col sm={12} className="line-info-container-trip-col">
                            {this.renderTripHeader(trip)}
                            {this.renderTrip(trip)}
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="line-info-container-map-col">
                            {this.renderTripHeader(trip)}
                            <LineInfoMap trip={trip} passing={passing} realTimePassing={realTimePassing} selectedTripPassing={selectedTripPassing} provider={provider} makeTooltipsPermanent={true} />
                        </Col>
                    </Row>
                );
            }
        }
    }

    renderTripHeader(trip) {
        const { isLoadingTrip, displayMap, displayTripInfo, minuteDelay } = this.state;
        const { onReturn } = this.props;

        if (undefined === trip || 0 === trip.passings.length) {
            return (
                <div className="line-info-header">
                    <div>
                        <Button className="line-info-back-button" variant="light" onClick={() => onReturn()}>
                            <div className={"icon-redo"} />
                        </Button>
                    </div>
                    <div className="line-info-panel-header text-align-center">
                        {isLoadingTrip ?
                            <Spinner animation="border" role="status" />
                            :
                            <span>{strings.noInfoToShow}</span>
                        }
                    </div>
                </div>
            );
        }

        const { passing, provider } = this.props;

        return (
            <div className="line-info-header">
                <Button className="line-info-back-button" variant="light" onClick={() => onReturn()}>
                    <div className={"icon-redo"} />
                </Button>
                <div className="line-info-small-screen-tabs">
                    <Button id="line-info-show-stops-button" className={`line-info-small-screen-tabs-link-button ${displayTripInfo ? "orange-text" : ""}`} variant="link" onClick={() => this.displayTripInfo()}>
                        {strings.showStops}
                    </Button>
                    <span className="orange-text">|</span>
                    <Button id="line-info-show-map-button" className={`line-info-small-screen-tabs-link-button ${displayMap ? "orange-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                        {strings.showMap}
                    </Button>
                </div>

                <div className="line-info-panel-header">
                    <div className="line-info-panel-header-provider-info">
                        {this.renderProviderLogo(provider)}
                        <div className="line-info-panel-header-trip-info">
                            <div><b>{passing.lineCode}</b> {passing.name}</div>
                            <div>{`${trip.passings[0].name} > ${trip.passings[trip.passings.length - 1].name}`}</div>
                            {
                                isLoadingTrip ?
                                    null
                                    :
                                    minuteDelay !== 0
                                        ? minuteDelay < -1 ? <div className="line-info-panel-header-trip-delay">{strings.aheadSchedule} {Math.abs(minuteDelay)}min</div>
                                            : minuteDelay >= 5 ? <div className="line-info-panel-header-trip-delay">{strings.delayed} {minuteDelay}min</div>
                                                : null
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPassingRealTime(tripPassing) {
        return -1 === tripPassing.duration ?
            null :
            <div><img src='icons/tempo_real.gif' className="rt-passing-info-gif" alt="Tempo Real" />{this.formatDuration(tripPassing.duration)}</div>;
    }

    renderPassingPlannedTime(displayTime, tripPassing) {
        return displayTime ?
            <span>{tripPassing.formattedTimeStamp}</span> :
            null;
    }

    renderPassingTime(tripPassing, displayPlannedTime) {
        if (tripPassing.isRT) {
            return this.renderPassingRealTime(tripPassing);
        }

        return this.renderPassingPlannedTime(displayPlannedTime, tripPassing);
    }

    renderTrip(trip) {
        if (undefined === trip || 0 === trip.passings.length) {
            return null;
        }

        const { passing } = this.props;
        const { isLoadingTrip } = this.state;

        if (isLoadingTrip) {
            return (
                <div className="text-align-center">
                    <Spinner animation="border" role="status" />
                </div>
            );
        }

        const displayPlannedTime = trip.passings
            .filter(passing => passing.isRT)
            .every(passing => passing.isDeterministic);

        return (
            <div className="line-info-panel">
                <Row>
                    <Col sm={6} xs={6}>
                        <span className="line-info-stops-title">{strings.lineInfoStopsTitle}</span>
                    </Col>
                    <Col sm={6} xs={6}>
                        <div className="icon-refresh2 line-info-refresh-button" onClick={() => this.handleTripRefresh()} />
                    </Col>
                </Row>

                <Container fluid className="line-info-passings-list">
                    {
                        trip.passings.map((tripPassing, index) =>
                            <Row key={`line-info-passing-${index}`} onClick={() => this.handleSelectTripPassing(tripPassing)}>
                                <Col xs={1}>
                                    <img src={passing.stopId === tripPassing.stopId ? "icons/highlightedMarker.png" : "icons/lineInfoMarker.png"} alt="" />
                                </Col>
                                <Col xs={9} className="d-flex justify-content-between">
                                    <div className="d-inline-block text-truncate">{tripPassing.name}</div>
                                    {this.renderMoreSchedsButton(passing.stopId === tripPassing.stopId)}
                                </Col>

                                <Col xs={2} className={tripPassing.isRT ? "rt-passing-info d-flex align-items-center justify-content-right white-space-no-wrap" : "d-flex align-items-center justify-content-right white-space-no-wrap"}>
                                    {this.renderPassingTime(tripPassing, displayPlannedTime)}
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
        );
    }

    renderMoreSchedsButton(isSelectedStop) {
        if (isSelectedStop) {
            return (
                <div className="white-space-no-wrap orange-text" onClick={() => this.handleMoreSchedsClick()}>
                    {strings.lineInfoMoreScheds}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <Container fluid className="line-info-container">
                {this.renderInfo()}
            </Container>
        );
    }
}
