import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { SearchCommand } from './commands/SearchCommand';
import { SearchBarResults } from './SearchBarResults';

export class SearchBar extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.timeoutId = undefined;
        this.stateKey = "searchBar";
        this.state = {
            commands: {
                stops: new SearchCommand()
            },
            searchText: "",
            showResults: false,
            results: []
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const { caller } = this.props;
        const state = getState(`${caller || "default"}-${this.stateKey}`);

        if (undefined !== state) {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        const { caller } = this.props;
        setState(`${caller || "default"}-${this.stateKey}`, this.state);
    }

    updateSearchText(e) {
        this.setState({
            searchText: e.target.value
        }, () => {
            if (this.timeoutId !== undefined) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(() => {
                this.performSearch();
                this.timeoutId = undefined;
            }, 500);
        });
    }

    updateSearchTextWithoutSearching(text) {
        this.setState({
            searchText: text
        }, () => {
            this.performSearch(false);
        });
    }

    clearSearchText() {
        const { onSearchClear } = this.props;

        onSearchClear();

        this.setState({
            searchText: ""
        }, () => {
            this.performSearch();
        });
    }

    handleResultClick(stop, recenter) {
        const { onSelect } = this.props;
        onSelect(stop, recenter);
        this.setState({
            searchText: stop.name,
            showResults: false
        });
    }

    handleFocus() {
        this.setState({
            showResults: true
        });
    }

    searchSuccessCallback(result, showResults = true) {
        this.setState({
            showResults: showResults,
            results: result
        });
    }

    performSearch(showResults = true) {
        const { searchText, commands } = this.state;
        if (3 > searchText.length) {
            this.setState({ results: [] });
        } else {
            commands.stops.performSearch(searchText, (r) => this.searchSuccessCallback(r, showResults));
        }
    }

    determineResultsToDisplay(results, caller) {
        if (caller.includes("planroute")) {
            return results.filter(r => r.type !== 4);
        } else if (caller.includes("schedulebystop")) {
            return results.filter(r => r.type !== 4 && r.type !== 3);
        } else {
            return results;
        }

    }

    renderResults() {
        const { results, showResults } = this.state;
        const { resultsClassName, caller } = this.props;

        if (0 === results.length || !showResults) {
            return null;
        }

        let resultsToDisplay = this.determineResultsToDisplay(results, caller);

        return <SearchBarResults className={resultsClassName} data={resultsToDisplay} onSelect={(stop, recenter) => this.handleResultClick(stop, recenter)} />;
    }

    render() {
        const { searchText } = this.state;
        const { className, placeholder, isRequired, caller } = this.props;
        return (
            <div className={className}>
                <input id={`searchbar-${caller}`} className="search-bar" type="text" placeholder={placeholder} value={searchText} onChange={(e) => this.updateSearchText(e)} onFocus={() => this.handleFocus()} autoComplete="off" required={isRequired} />
                <button type="button" className="search-bar-cancel-icon" onClick={() => this.clearSearchText()}>
                    <div className="icon-error" />
                </button>
                {this.renderResults()}
            </div>
        );
    }
}