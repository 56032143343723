import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { strings } from '../resources/strings';
import { ContactCommand } from './commands/ContactCommand';

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commands: {
                contacts: new ContactCommand()
            },
            sending: false,
            sent: false,
            selectedSubject: 0,
            result: undefined
        };
    }

    sendMail = (e) => {
        e.preventDefault();

        this.setState({ sending: true });
        var data = new FormData();        
        data.append("name", e.target.name.value);
        data.append("email", e.target.email.value);
        data.append("phone", e.target.phone.value);
        data.append("address", e.target.address.value);
        data.append("postalCode", e.target.postalCode.value);
        data.append("subject", e.target.subjectType.value);
        data.append("reason", e.target.reason.value);
        data.append("message", e.target.message.value);
        if (e.target.file.files.length > 0) {
            data.append("file", e.target.file.files[0]);
        }

        this.state.commands.contacts.sendEmail(data, (r) => this.sendSuccessCallback(r), (r) => this.sendErrorCallback(r));
    }

    handleSubjectChange = (e) => {
        e.preventDefault();

        this.setState({ selectedSubject: e.target.value });
    }

    sendSuccessCallback(result) {
        this.setState({
            sending: false,
            sent: true,
            result: true
        });
    }

    sendErrorCallback(result) {
        this.setState({
            sending: false,
            sent: false,
            result: false
        });
    }

    render() {
        
        const subjectOptions = [strings.contactInfo, strings.contactCompliments, strings.contactComplaints, strings.contactSuggestions];

        const reasonOptions = [
            [
                strings.contactInfoDispatches,
                strings.contactInfoSchedule,
                strings.contactInfoCustomer,
                strings.contactInfoInvoiceRequest,
                strings.contactInfoPathsStops,
                strings.contactInfoLostFound,
                strings.contactInfoTicket,
                strings.contactInfoDisableTransport,
                strings.contactInfoOther,
            ],
            [
                strings.contactCompliments
            ],
            [
                strings.contactComplaintsPassengerRelations,
                strings.contactComplaintsInvoiceRequest,
                strings.contactComplaintsDelays,
                strings.contactComplaintsNoShows,
                strings.contactComplaintsSchedulesFrequencies,
                strings.contactComplaintsUserInformation,
                strings.contactComplaintsPathStops,
                strings.contactComplaintsBaggage,
                strings.contactComplaintsRefunds,
                strings.contactComplaintsTicket,
                strings.contactComplaintsIncapacityOfTransport,
                strings.contactComplaintsConfort,
                strings.contactComplaintsTechnicalProblems,
                strings.contactComplaintsOther
            ],
            [
                strings.contactSuggestions
            ]
        ];

        return (            
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        {/*<div className="mt-5">*/}
                        {/*    <b>{strings.contactHeader}</b><br />*/}
                        {/*    <b>Praça António Raposo Tavares<br />*/}
                        {/*        7800 - 426 Beja</b><br />*/}
                        {/*    <b>{strings.email}:</b> geral@trimbal.pt<br />*/}
                        {/*    <b>{strings.phone}:</b> 284 401 227<br /><br />*/}
                        {/*    <p>{strings.contactWorkingDays}<br />*/}
                        {/*    {strings.contactSaturdays}<br />*/}
                        {/*    {strings.contactSundays}</p>*/}
                        {/*</div>*/}
                        <Form onSubmit={this.sendMail} className="mb-3">
                            <div className="mt-3">
                                {strings.sendMessage}
                            </div>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control className="contact-input" required placeholder={strings.name} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control type="email" className="contact-input" required placeholder={strings.email} />
                                <Form.Text className="text-muted">
                                    {strings.emailWarning}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Control className="contact-input" placeholder={strings.phone} />
                            </Form.Group>
                            <Row>
                                <Form.Group className="mb-3 col-md-8" controlId="address">
                                    <Form.Control className="contact-input" placeholder={strings.address} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-4" controlId="postalCode">
                                    <Form.Control className="contact-input" placeholder={strings.postalCode} />
                                </Form.Group>
                            </Row>

                            <div className="mt-3">
                                {strings.provideSubject}
                            </div>

                            <Form.Group className="mb-3" controlId="subjectType">
                                <Form.Select className="contact-input" aria-label={strings.subject} onChange={(e) => this.handleSubjectChange(e)}>
                                    {
                                        subjectOptions.map((option, index) =>
                                            <option key={index} value={index}>{option}</option>
                                        )
                                    }

                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="reason">
                                <Form.Select className="contact-input" aria-label={strings.reason}>
                                    {
                                        reasonOptions[this.state.selectedSubject].map((option, index) =>
                                            <option key={option} value={index}>{option}</option>
                                        )
                                    }

                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="message">
                                <Form.Control as="textarea" rows={5} className="contact-input" required placeholder={strings.message} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="file">
                                <Form.Label>{strings.file}</Form.Label>
                                <Form.Control type="file" className="contact-input" size="sm" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" />
                                <Form.Text className="text-muted">
                                    {strings.fileHelp}
                                </Form.Text>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={this.state.sending || this.state.sent}>
                                {this.state.sent ? strings.sent : strings.send}
                            </Button>

                            {this.state.result !== undefined
                                ? <Alert className="mt-3" variant={this.state.result ? "success" : "danger"}>
                                    {this.state.result
                                        ? strings.emailSuccess
                                        : strings.emailError}
                                </Alert>
                                : null}
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <p>{strings.moreInfoSupportline}</p>
                </Row>
            </Container>
        );
    }
}
