import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class ServiceOnDemand extends Component {
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="mt-5">
                            <h2>{strings.serviceOnDemand}</h2>
                            <br />
                            <p>{strings.serviceOnDemandHowWorks}</p>
                            <ol>
                                <li>{strings.serviceOnDemandWorkingOne}</li>
                                <li>{strings.serviceOnDemandWorkingTwo}</li>
                                <li>{strings.serviceOnDemandWorkingThree}</li>
                            </ol>
                            <br />
                            <p>{strings.serviceOnDemandPlacesHeader}</p>
                            <ul dangerouslySetInnerHTML={{ __html: strings.serviceOnDemandPlacesFafe }} />
                            <br />
                            <ul dangerouslySetInnerHTML={{ __html: strings.serviceOnDemandPlacesMondimBastos }} />
                            <br />
                            <ul dangerouslySetInnerHTML={{ __html: strings.serviceOnDemandPlacesPovoaLanhoso }} />
                            <br />
                            <ul dangerouslySetInnerHTML={{ __html: strings.serviceOnDemandPlacesVizela }} />
                            <br />
                            <ul dangerouslySetInnerHTML={{ __html: strings.serviceOnDemandPlacesCallSchedule }} />
                            <br/>
                            <br/>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}