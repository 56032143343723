import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//import { strings } from '../../resources/strings';

export class TicketInfo extends Component {
    render() {
        return (
            <Container fluid className="mb-5 tariff">
                <Row className="mt-5">
                    <a href="docs/Tarifas_AVE MOBILIDADE.pdf" target="_blank">Descarregar Informação Tarifária Completa</a>
                </Row>
                <Row className="mt-5">
                    <h1>Temos sempre uma solução a pensar em si.</h1>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="darkgray">BILHETE <b>SIMPLES A BORDO</b><br/>INTEIRO E MEIO</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido a bordo;</li>
                                <li>Permite a realização de 1 viagem no autocarro onde foi adquirido;</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="red">CARTÃO VALOR</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido e recarregado na bilheteira;</li>
                                <li>Tem de ser validado em cada viagem efetuada;</li>
                                <li>Permite a realização de viagens em qualquer linha e Origem/Destino da rede.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="red"><b>PRÉ-COMPRADO</b><br />10 VIAGENS (PC10)</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido e recarregado na bilheteira;</li>
                                <li>Tem de ser validado em cada viagem efetuada;</li>
                                <li>Permite a realização de 10 viagens na Origem/Destino escolhidos;</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="lightgray">PASSE <b>MENSAL</b></h5>
                            <ul>
                                <li>Título <b>personalizado, pessoal e intransmissível;</b></li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Tem de ser validado a bordo do autocarro.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="green">PASSE <b>PESSOA MOBILIDADE<br />CONDICIONADA (PPMC)</b> <small>- PASSAGEIROS</small></h5>
                            <ul>
                                <li>Destinado a pessoas com um grau de incapacidade igual ou superior a 60% e que possuam o Cartão Municipal de Pessoa com Deficiência.</li>
                                <li>Funciona nos mesmos moldes do Passe Mensal (PM), mas com desconto de 50% sobre a tarifa aplicável.</li>
                            </ul>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="bordeaux">PASSE <b>SÉNIOR</b></h5>
                            <ul>
                                <li>Título <b>personalizado</b>, pessoal e intransmissível;</li>
                                <li>Destinado a <b>pessoas com 65 anos ou mais</b> e que possuam o Cartão Municipal do Idoso ou documento equivalente, dos 8 concelhos que integram a CIM do Ave (Cabeceiras de Baste; Fafe; Guimarães; Mondim de Basto; Póvoa de Lanhoso; Vieira do Minho; Vizela; Vila Nova de Famalicão);</li>
                                <li>É válido em todo o concelho de residência do utilizador.</li>
                                <li>Preço: 14,35€</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="yellow">PASSE <b>4_18 GRATUITO</b></h5>
                            <ul>
                                <li>Título <b>Personalizado</b>, pessoal e intransmissível;</li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Renovável anualmente.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="blue">PASSE <b>SUB23 GRATUITO</b></h5>
                            <ul>
                                <li>Título <b>Personalizado</b>, pessoal e intransmissível;</li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Tem de ser validado a bordo do autocarro;</li>
                                <li>*Estão abrangidos os jovens até aos 24 anos, que frequentam cursos com ciclo de estudos integrado, designadamente Arquitetura e Urbanismo, Ciências Farmacêuticas, Medicina, Medicina Dentária e Medicina Veterinária;</li>
                                <li>Renovável anualmente.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="purple">PASSE <b>SOCIAL PART</b></h5>
                            <ul>
                                <li>Título <b>personalizado</b>, pessoal e intransmissível;</li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com número de viagens ilimitadas;</li>
                                <li>Tem de ser validado a bordo do autocarro;</li>
                                <li>Destina-se a residentes dos 8 concelhos que integram a CIM do Ave (Cabeceiras de Baste; Fafe; Guimarães; Mondim de Basto; Póvoa de Lanhoso; Vieira do Minho; Vizela; Vila Nova de Famalicão);</li>
                                <li>Desconto de 50% face ao preço do Passe Mensal.</li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}