import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const contactApiRoutes = {
    defaultRoute: "contact/"
};

export class ContactAPI {
    static sendEmail(request) {
        const url = `${contactApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.POST,
            body: request,
            contentType: ContentType.MULTIPART,
            key: APIEncoder.getApiKey()
        });
    }
}