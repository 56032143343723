import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class About extends Component {
    //RENDER

    render() {
        return (
            <Container fluid>
                <Row>
                    <div className="about-page-header">{strings.aboutUs}</div>
                </Row>
                <Row>
                    <div className="mt-5">{strings.about1}</div>
                    <div className="mt-5">{strings.about2}</div>
                </Row>
            </Container>
        );
    }
}
