import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        aboutUs: "About Us",
        about1: "Ave Mobilidade is the public passenger transport network serving the populations of Cabeceiras de Basto, Fafe, Guimarães, Mondim de Basto, Póvoa de Lanhoso, Vieira do Minho, Vila Nova de Famalicão and Vizela.",
        about2: "With 161 vehicles, 42 two municipal lines, 31 intercity lines and 25 interregional lines, mobility in the region is thus strengthened with a public road transport policy that aims to respond to the true conveniences of the community. Among the services available are the adaptation of the fleet to respond to the transport needs of passengers with reduced mobility, Wi-Fi connection on board, a website and APP. In this way, it is easier for passengers to plan their trips, as they can use time-search tools by Origin/Destination or use a customer support line, available on weekdays between 9am and 7pm , among other amenities.",
        home: "Home",
        nextDepartures: "Next Departures",
        nextDeparturesFrom: "Next departures from",
        network: "Network",
        planRoute: "Plan Route",
        schedules: "Schedules",
        schedulesByLine: "Schedules by Line",
        schedulesByStop: "Schedules by Stop",
        schedulesDisclaimer: "The displayed schedules are valid for the present day, other periods may have different schedules",
        messages: "News / Informations",
        ticketOffice: "Ticket Offices",
        about: "About",
        searchPlaceholder: "Select (Stop / Line / Ticket Office)",
        originStopPlaceHolder: "Departure",
        destinationStopPlaceHolder: "Arrival",
        destination: "Destination",
        planRouteButton: "Plan Trip",
        plannedRoutes: "Trip options",
        duration: "Duration",
        includes: "Includes",
        changes: "change(s)",
        planRouteTimeFilterSelectNow: "Now",
        planRouteTimeFilterSelectStartDate: "Select departure date",
        planRouteTimeFilterSelectedStartDate: "Departure at",
        planRouteTimeFilterSelectEndDate: "Select arrival date",
        planRouteTimeFilterSelectedEndDate: "Arrival at",
        planRouteTimeDisclaimer: "Travel options more suitable for a maximum interval of up to 4 hours from the selected departure time (4 hours earlier for Arrival at option)",
        checkSchedules: "Check Schedules",
        availableLines: "Lines",
        walkAround: "Walk around",
        estimatedWaitingTime: "Estimated waiting time between",
        arrivalEstimatedAt: "Arrival estimated at",
        and: "and",
        or: "or",
        lineInfoStopsTitle: "Stops",
        lineInfoMoreScheds: "more schedules",
        showStops: "Show stops",
        showSchedule: "Show schedule",
        showTrip: "Show route",
        showMap: "Show map",
        moreSchedsTitle: "Schedules",
        noMessagesToShow: "No messages to show",
        noPassingsToShow: "No passings to show",
        noTripsToShow: "No trips to show",
        noInfoToShow: "No information to show",
        selectDate: "Select date",
        provider: "Provider",
        line: "Line",
        viewLine: "View line",
        selectPath: "Select path",
        direction: "Direction",
        directionGo: "Go",
        directionReturn: "Return",
        directionCircular: "Circular",
        daytype: "Day Type",
        locale: "en",
        hoursFilter: "Filter by hours",
        initialHourFilter: "Start time",
        finalHourFilter: "End time",
        filter: "Filter",
        close: "Close",
        done: "Done",
        copyright: "Powered by OPT",
        allRightsReserved: "All rights reserved",
        privacyPolicy: "Privacy Policy",
        notFoundTitle: "404 - Page not found",
        notFoundDescription: "The page you are trying to access does not exist.",
        developedFor: "Developed for Ave Mobilidade",
        contact: "Contact Us",
        name: "Name",
        email: "Email",
        emailWarning: "We'll never share your email with anyone else.",
        subject: "Subject",
        send: "Send",
        sent: "Message sent",
        general: "General",
        emailSuccess: "Message sent successfully.",
        emailError: "An error occurred, please try again later.",
        pageNotFound: "Page not found",
        delayed: "Delayed",
        aheadSchedule: "Ahead of schedule",
        complaints: "TRIMBAL Complaints",
        complaintsBook: "Complaints Book",
        phone: "Telephone",
        sendMessage: "Send us a message:",
        seeMore: "See more",
        passengerRights: "Passenger's Rights and Duties",
        ticketInfo: "Tickets and fares",
        serviceOnDemand: "Services on demand",
        moreInfoSoon: "Service will be available soon...",
        serviceOnDemandHowWorks: "How does Service on Demand work?",
        serviceOnDemandWorkingOne: "If you intend to travel from one of the locations with Service on Demand signs, call 253 415 015* on the business day prior to your trip, until 1pm.",
        serviceOnDemandWorkingTwo: "A reservation will be made, and you'll be informed of the location and time of the transport.",
        serviceOnDemandWorkingThree: "In the next day, the transport will show up at the appointed time and place.",
        serviceOnDemandPlacesHeader: "Places, per county, covered by SD:",
        serviceOnDemandPlacesFafe: "<b>Fafe</b><br/>Aguiar e S. Pedro, Argande, Assento, Atalaia, Bairro e Crasto, Barbeita de Baixo, Barbeita de Cima, Cabeceiros, Carvalhal, Casal e Quintãs, Caselhos, Castanheira, Certal, Cestelo, Compostela, Cortegaça, Crasto, Souto, Fornelo, Leiras, Macieiro, Moinhos, Mós, Ortezedo, Outeiro Mau, Pinheiro, Seara e Torre, Portas, Portela e Lages de S. João, Ribeira, Samorinha, Santa Cruz, São Sebastião, Soalheira, Soutelo, Tabaçó e Torpeza.",
        serviceOnDemandPlacesMondimBastos: "<b>Mondim de Basto</b><br/>Bormela (Fundão), Carvalhais, Covas, Côvelo, Paço, Pardelhas, Pedreira, Póvoa, Sobreira, Suidros, Travassos.",
        serviceOnDemandPlacesPovoaLanhoso: "<b>Póvoa de Lanhoso</b><br/>Barrio, Berraria, Bezerral, Bobeiro, Cabanelas, Cancelos, Carreira, Carvalho, Cimo de Vila, Cruzeiro, Devesa, Lagido, Laranjal, Monte, Outeiro, Paço Velho, Paredes, Penedos, Portas, Pousada, Pousadela, Pregal, Quintela, Reboreda, Tinocos, Ventosela, Vila e Vilar.",
        serviceOnDemandPlacesVizela: "<b>Vizela</b><br/>Barrado, Borreiros/Outeirinho, Bouça, Bouço.",
        serviceOnDemandPlacesCallSchedule: "<b>*Working hours</b><br/>9h00 – 13h00<br/>14h00 – 18h00<br/>(call to national fixed network).",
        fares: "Fares",
        landingPageRoutePlanning: "Plan Route",
        landingPageNetwork: "Network",
        landingPageTickets: "Tickets, fares and cards",
        landingPageServiceOnDemand: "Services on demand",
        landingPageContacts: "Contacts",
        simpleTicket: "SIMPLE TICKET",
        kmSteps: "BRACKETS KMS",
        full: "FULL",
        half: "HALF",
        code: "CODE",
        driverFare: "DRIVER FARE",
        prePurchased: "PRE-PURCHASED",
        automatedLines: "AUTOMATED LINES",
        lineSubscriptions: "LINE SUBSCRIPTION",
        discount60: "60% discount",
        discount25: "25% discount",
        disclaimer: "The stop times between towns are merely indicative, therefore you should consider the time for the town that precedes the one you're in.",
        contactHeader: "Office / Sales",
        contactWorkingDays: "Working days 06h30 to 20h30",
        contactSaturdays: "Saturdays 06h30 to 10h30 / 13h30 to 20h30",
        contactSundays: "Sundays 07h45 to 10h45 / 13h45 to 20h30",
        notes: "View Notes",
        passes418s23: "4_18 and Sub23 Passes",
        pass418: "4_18 Pass",
        pass418Users: "The \"4_18\" pass is intended for non-higher education students, from 4 to 18 years of age, who do not benefit from school transport.",
        pass418Conditions: "The transport ticket «4_18@escola.tp» will have the following discounts on the price of the monthly passes in effect, namely intermodal, combined and network or line passes:",
        pass418ConditionA: "60% for students benefiting from Grade \"A\" of the School Social Action;",
        pass418ConditionB: "25% for the remaining non-higher education students, from 4 to 18 years old, not covered by the provisions of the previous paragraph.",
        pass418Requirements: "To get access to these discounts, you must:",
        pass418RequirementA: "obtain from the educational establishment a 4_18 Enrollment Declaration, which proves the student's enrollment, in which it is expressly mentioned that he is not covered by the school transport established by Decree-Law nº. 21/2019, from January 30th, and whether or not they are a beneficiary of the School Social Action and, if so, at which level;",
        pass418RequirementB: "fill the requisition requesting access to the benefit;",
        pass418RequirementC: "deliver these documents for issuing the 4_18 pass card. The card is valid for a maximum period of 4 years, until the end of the month in which the student turns 19.",
        pass418Renewal: "At the beginning of each academic year it is necessary to present a new declaration from the educational establishment, in order to be entitled to the reductions provided by the 4_18 pass.",
        passS23: "Sub23 passes (public or private higher education students, up to 23 years old)",
        passS23ShortName: "Sub23 Pass",
        passS23Users: "The sub23 pass is intended for higher education students up to and including 23 years of age and, in the case of higher education students enrolled in Medicine and Architecture courses, up to and including 24 years of age.",
        passS23Conditions: "The transport ticket «sub23@superior.tp» will have the following discounts on the price of the monthly passes in effect, namely intermodal, combined and network or line passes:",
        passS23ConditionA: "60% for students benefiting from Direct Social Action in Higher Education;",
        passS23ConditionB: "25% for the remaining Higher Education students.",
        passS23Requirements: "To access these discounts, you must:",
        passS23RequirementA: "obtain from the educational establishment a Sub23 Enrollment Declaration, which proves the student's enrollment, as well as whether or not he is a beneficiary of Direct Social Action in Higher Education;",
        passS23RequirementB: "deliver these sub23 pass card documents. The card is valid for a maximum period of 4 years, up to and including 23 years of age and, in the case of higher education students enrolled in Medicine and Architecture courses, up to and including 24 years of age.",
        passS23Renewal: "At the beginning of each academic year, it is necessary to present an updated statement from the educational establishment to the transport provider, in order to be entitled to the reductions provided by the sub23 pass again.",
        passes418s23MoreInfo: "For more information, access the respective links:",
        passAntigoCombatente: "Antigo Combatente (Former Combatant) Pass",
        passAntigoCombatenteDescription: "Antigo Combatente pass is a tariff modality that grants an exemption from the payment of the monthly ticket.",
        passAntigoCombatenteUsersAndConditions: "It is intended for all former combatants, holders of the former combatant card, as well as for the widow and widower of the former combatant. To gain access to the Antigo Combatente Pass, the potential beneficiary must fill and submit the respective application for membership, accompanied by the following documents:",
        passAntigoCombatenteConditionA: "Presentation of a former combatant's card or a former combatant's widow and widower's card;",
        passAntigoCombatenteConditionB: "Presentation of ID card or other equivalent valid title;",
        passAntigoCombatenteConditionC: "Residence certificate issued by AT.",
        passAntigoCombatenteMoreInfo: "For more information, access the following link:",
        passAntigoCombatenteLink: "Antigo Combatente Pass (imt-ip.pt)",
        town: "Location",
        address: "Address",
        postalCode: "Postal Code",
        schedule: "Schedule",
        workingDays: "Working Days",
        saturdays: "Saturdays",
        mondayToSaturday: "Monday to Saturday",
        sundays: "Sundays",
        saturdaysSundaysHolidays: "Saturdays, Sundays and Holidays",
        daily: "Daily",
        exceptSunday: "Monday to Saturday",
        saturdaysHolidays: "Saturdays and Holidays",
        sundaysMondaysIfHolidays: "Sundays and Mondays (if holiday)",
        contactInfo: "Information",
        contactCompliments: "Compliments",
        contactComplaints: "Complaints",
        contactSuggestions: "Suggestions",
        reason: "Reason",
        contactInfoSchedule: "Schedules and trips",
        contactInfoCustomer: "Information made available to passengers",
        contactInfoDispatches: "Dispatches",
        contactInfoDisableTransport: "Transport of passengers in a wheelchair",
        contactInfoOther: "Other subjects",
        contactInfoPathsStops: "Routes and stops",
        contactInfoTicket: "Tickets and Tariffs",
        contactInfoLostFound: "Lost and found",
        contactInfoInvoiceRequest: "Invoice Request",
        contactComplaintsPassengerRelations: "Customer service and relationship with passengers",
        contactComplaintsDelays: "Early/Delay trip",
        contactComplaintsNoShows: "Trips that were not done",
        contactComplaintsConfort: "Confort and cleaning of the vehicles",
        contactComplaintsOther: "Other subjects",
        contactComplaintsRefunds: "Ticket refunds",
        contactComplaintsTicket: "Tickets and Tariffs",
        contactComplaintsPathStops: "Routes and stops",
        contactComplaintsInvoiceRequest: "Invoice Request",
        contactComplaintsSchedulesFrequencies: "Schedules and frequencies",
        contactComplaintsUserInformation: "Information made available to users",
        contactComplaintsBaggage: "Lost or damaged luggage",
        contactComplaintsIncapacityOfTransport: "Inability to transport all users",
        contactComplaintsTechnicalProblems: "Technical Problems",
        refundsPolicy: "Return policies",
        provideSubject: "Please, indicate the subject for this contact",
        moreInfoSupportline: "For additional information contact our customer support line +351 253 009 900 (available from 9am to 7pm on working days). National landline network phone call.",
        file: "Attachment",
        fileHelp: "Files should be no bigger than 2 MB. Allowed file types include: jpg jpeg png pdf doc docx."
    },
    pt: {
        aboutUs: "Sobre Nós",
        about1: "A Ave Mobilidade é a rede de transportes públicos de passageiros ao serviço das populações de Cabeceiras de Basto, Fafe, Guimarães, Mondim de Basto, Póvoa de Lanhoso, Vieira do Minho, Vila Nova de Famalicão e Vizela.",
        about2: "Com 161 viaturas, 42 duas linhas municipais, 31 intermunicipais e 25 linhas inter-regionais, a mobilidade na região está, assim, fortalecida com uma política de transporte público rodoviário que visa responder às verdadeiras conveniências da comunidade. De entre os serviços disponibilizados contam-se a adaptação da frota para responder às necessidades de transporte de passageiros com mobilidade condicionada, ligação Wi-fi a bordo, um site e APP.  Deste modo, é mais fácil aos passageiros fazer o planeamento das suas viagens, já que podem utilizar ferramentas de pesquisa de horários por Origem/Destino ou servir-se de uma linha de apoio ao cliente, disponível aos dias úteis entre as 9 e as 19h, entre outras comodidades.",
        home: "Início",
        nextDepartures: "Próximas Partidas",
        nextDeparturesFrom: "Próximas partidas de",
        network: "Rede",
        planRoute: "Planear Rota",
        schedules: "Horários",
        schedulesByLine: "Horários por Linha",
        schedulesByStop: "Horários por Paragem",
        schedulesDisclaimer: "Os horários apresentados são válidos para o dia atual, podendo não ter correspondência para outros períodos",
        messages: "Notícias / Informações",
        ticketOffice: "Postos de Venda",
        about: "Acerca de",
        searchPlaceholder: "Pesquisar (Paragem / Linha / Posto de Venda)",
        originStopPlaceHolder: "Partida",
        destinationStopPlaceHolder: "Chegada",
        destination: "Destino",
        planRouteButton: "Planear Viagem",
        plannedRoutes: "Opções de viagem",
        duration: "Duração",
        includes: "Inclui",
        changes: "transbordo(s)",
        planRouteTimeFilterSelectNow: "Agora",
        planRouteTimeFilterSelectStartDate: "Escolher data de partida",
        planRouteTimeFilterSelectedStartDate: "Partida às",
        planRouteTimeFilterSelectEndDate: "Escolher data de chegada",
        planRouteTimeFilterSelectedEndDate: "Chegada às",
        planRouteTimeDisclaimer: "Opções de viagem mais adequadas para um intervalo máximo de até 4 horas a partir da hora indicada(4 horas antes para opção de Chegada às)",
        checkSchedules: "Consultar Horários",
        availableLines: "Linhas",
        walkAround: "Ir a pé cerca de",
        estimatedWaitingTime: "Tempo de espera estimado entre as",
        arrivalEstimatedAt: "Chegada estimada às",
        and: "e",
        or: "ou",
        lineInfoStopsTitle: "Paragens",
        lineInfoMoreScheds: "mais horários",
        showStops: "Ver paragens",
        showSchedule: "Ver horário",
        showTrip: "Ver viagem",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horários",
        noMessagesToShow: "Sem mensagens",
        noPassingsToShow: "Sem tempos de passagem",
        noTripsToShow: "Sem viagens para mostrar",
        noInfoToShow: "Sem informação para mostrar",
        selectDate: "Seleccione uma data",
        provider: "Operador",
        line: "Linha",
        viewLine: "Ver linha",
        selectPath: "Escolha um percurso",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Volta",
        directionCircular: "Circular",
        daytype: "Tipo de Dia",
        locale: "pt",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        close: "Fechar",
        done: "Concluído",
        copyright: "Powered by OPT",
        allRightsReserved: "Todos os direitos reservados",
        privacyPolicy: "Política de Privacidade",
        notFoundTitle: "404 - Página não encontrada",
        notFoundDescription: "A página à qual está a tentar aceder não existe.",
        developedFor: "Desenvolvido para Ave Mobilidade",
        contact: "Contacto",
        name: "Nome",
        email: "Email",
        emailWarning: "O seu endereço nunca será partilhado com mais nenhuma organização.",
        subject: "Assunto",
        send: "Enviar",
        sent: "Mensagem Enviada",
        general: "Geral",
        emailSuccess: "Mensagem enviada com sucesso.",
        emailError: "Ocorreu um erro, por favor tente mais tarde.",
        pageNotFound: "Página não encontrada",
        delayed: "Atrasado",
        aheadSchedule: "Adiantado",
        complaints: "Reclamações TRIMBAL",
        complaintsBook: "Livro de Reclamações",
        phone: "Telefone",
        sendMessage: "Envie-nos uma mensagem:",
        seeMore: "Ver mais",
        passengerRights: "Direitos e Deveres dos Passageiros",
        ticketInfo: "Títulos e tarifas",
        serviceOnDemand: "Transporte a pedido",
        moreInfoSoon: "Serviço brevemente disponível...",
        serviceOnDemandHowWorks: "Como funciona o Transporte a Pedido?",
        serviceOnDemandWorkingOne: "Se pretende viajar a partir de uma das localidades com sinalética de Transporte a Pedido, contacte o nº 253 415 015* no dia útil anterior ao da viagem, até às 13h.",
        serviceOnDemandWorkingTwo: "Será feita a reserva, informando-o do local e hora de passagem do transporte.",
        serviceOnDemandWorkingThree: "No dia seguinte, na hora e local marcado, o transporte aparece.",
        serviceOnDemandPlacesHeader: "Localidades, por município, abrangidos pelo TP:",
        serviceOnDemandPlacesFafe: "Fafe: Aguiar e S. Pedro, Argande, Assento, Atalaia, Bairro e Crasto, Barbeita de Baixo, Barbeita de Cima, Cabeceiros, Carvalhal, Carvalho, Casal e Quintãs, Caselhos, Castanheira, Certal, Cestelo, Compostela, Cortegaça, Crasto, Fornelo, Lameiro, Leiras, Macieiro, Moinhos, Ortezedo, Pinheiro, Seara e Torre, Portas, Portela e Lages de S. João, Ribeira, Samorinha, São Sebastião, Soalheira, Soutelo, Tabaçó, Torpeza.",
        serviceOnDemandPlacesMondimBastos: "Mondim de Basto: Barreiro, Bormela (Fundão), Carvalhais, Covas, Côvelo, Paço, Pardelhas, Pedreira, Póvoa, Sobreira, Suidros, Travassos.",
        serviceOnDemandPlacesPovoaLanhoso: "Póvoa de Lanhoso: Barrio, Bezerral, Bobeiro, Bolhoso, Cabanelas, Cancelos, Carreira, Carvalho, Cimo de Vila, Cruzeiro, Devesa, Lagido, Laranjal, Monte, Paço, Paço Velho, Paredes, Penedos, Portas, Pousada, Pousadela, Pregal, Quintela, Reboreda, Tinocos, Ventosela, Vila, Vilar.",
        serviceOnDemandPlacesVizela: "Vizela: Barrado, Borreiros/Outeirinho, Bouça.",
        serviceOnDemandPlacesCallSchedule: "*Horário de funcionamento 9h00 – 13h00 / 14h – 18h (chamada para rede fixa nacional).",
        fares: "Tarifário",
        landingPageRoutePlanning: "Planear rota",
        landingPageNetwork: "Rede",
        landingPageTickets: "Títulos, tarifas e cartões",
        landingPageServiceOnDemand: "Serviços a pedido",
        landingPageContacts: "Contactos",
        simpleTicket: "BILHETE SIMPLES",
        kmSteps: "ESCALÕES KMS",
        full: "INTEIRO",
        half: "MEIO",
        code: "CÓD.",
        driverFare: "TARIFA MOTORISTA",
        prePurchased: "PRÉ-COMPRADOS",
        automatedLines: "CARREIRAS AUTOMATIZADAS",
        lineSubscriptions: "ASSINATURA LINHA",
        discount60: "60% desconto",
        discount25: "25% desconto",
        disclaimer: "Os horários das paragens entre localidades são meramente indicativos pelo que deverá considerar o horário da localidade que antecede o local onde se encontra.",
        contactHeader: "Instalações / Posto de Venda",
        contactWorkingDays: "Dias úteis 06h30 às 20h30",
        contactSaturdays: "Sábados 06h30 às 10h30 / 13h30 às 20h30",
        contactSundays: "Domingo 07h45 às 10h45 / 13h45 às 20h30",
        notes: "Ver Notas",
        passes418s23: "Passes 4_18 e Sub23",
        pass418: "Passe 4_18",
        pass418Users: "O passe \"4_18\" destina-se aos estudantes do ensino não superior, dos 4 aos 18 anos, inclusive, que não beneficiem de transporte escolar.",
        pass418Conditions: "O título de transporte passe «4_18@escola.tp» terá os seguintes descontos sobre o preço dos passes mensais em vigor, designadamente os intermodais, os combinados e os passes de rede ou de linha:",
        pass418ConditionA: "60% para os estudantes beneficiários do Escalão \"A\" da Ação Social Escolar;",
        pass418ConditionB: "25% para os restantes estudantes do ensino não superior, dos 4 aos 18 anos, inclusive, não abrangidos pelo disposto na alínea anterior.",
        pass418Requirements: "Para ter acesso àqueles descontos, é necessário:",
        pass418RequirementA: "obter junto do estabelecimento de ensino uma Declaração de Matrícula 4_18, que comprove a inscrição do aluno, na qual esteja referido expressamente não se encontrar abrangido pelo transporte escolar estabelecido pelo Decreto-Lei n.º 21/2019, de 30 de janeiro, bem como se é ou não beneficiário da Ação Social Escolar e, em caso afirmativo, qual o seu escalão;",
        pass418RequirementB: "preencher a requisição a solicitar o acesso ao benefício;",
        pass418RequirementC: "entregar estes documentos para emissão do cartão de passe 4_18. O cartão é válido por períodos máximos de 4 anos, até ao final do mês em que o aluno completa 19 anos.",
        pass418Renewal: "No início de cada ano letivo, é necessário apresentar, nova declaração do estabelecimento de ensino, para poder voltar a ter direito às reduções proporcionadas pelo passe 4_18.",
        passS23: "Passes sub23 (estudantes do ensino superior público ou privado, até aos 23 anos)",
        passS23ShortName: "Passe Sub23",
        passS23Users: "O passe sub23 destina-se aos estudantes do ensino superior até aos 23 anos, inclusive e, no caso dos estudantes de ensino superior inscritos nos cursos de Medicina e de Arquitetura, até aos 24 anos de idade inclusive.",
        passS23Conditions: "O título de transporte passe «sub23@superior.tp» terá os seguintes descontos sobre o preço dos passes mensais em vigor, designadamente os intermodais, os combinados e os passes de rede ou de linha:",
        passS23ConditionA: "60% para os estudantes beneficiários da Ação Social Direta no Ensino Superior;",
        passS23ConditionB: "25% para os restantes estudantes do Ensino Superior.",
        passS23Requirements: "Para ter acesso àqueles descontos, é necessário:",
        passS23RequirementA: "obter junto do estabelecimento de ensino uma Declaração de Matrícula sub23, que comprove a inscrição do aluno, bem como se é ou não beneficiário da Ação Social Direta no Ensino Superior;",
        passS23RequirementB: "entregar estes documentos do cartão de passe sub23. O cartão é válido por períodos máximos de 4 anos, até aos 23 anos, inclusive e, no caso dos estudantes de ensino superior inscritos nos cursos de Medicina e de Arquitetura, até aos 24 anos de idade, inclusive.",
        passS23Renewal: "No início de cada ano letivo, é necessário apresentar, ao operador de transportes, declaração atualizada do estabelecimento de ensino, para voltar a ter direito às reduções previstas pelo passe sub23.",
        passes418s23MoreInfo: "Para mais informações aceda aos respetivos links:",
        passAntigoCombatente: "Passe Antigo Combatente",
        passAntigoCombatenteDescription: "O Passe de Antigo Combatente é uma modalidade tarifária que confere uma isenção do pagamento do título mensal.",
        passAntigoCombatenteUsersAndConditions: "Destina-se a todos os antigos combatentes, detentores do cartão de antigo combatente, bem como para a viúva e viúvo do antigo combatente. Para ter acesso ao Passe de Antigo Combatente, o potencial beneficiário deverá preencher e apresentar o respetivo requerimento de adesão, acompanhado dos seguintes documentos:",
        passAntigoCombatenteConditionA: "Apresentação de cartão de antigo combatente ou de cartão de viúva e viúvo de antigo combatente;",
        passAntigoCombatenteConditionB: "Apresentação do cartão de cidadão ou outro título válido equivalente;",
        passAntigoCombatenteConditionC: "Certidão de domicílio fiscal emitida pela AT.",
        passAntigoCombatenteMoreInfo: "Para mais informações aceda ao seguinte link:",
        passAntigoCombatenteLink: "Passe de Antigo Combatente (imt-ip.pt)",
        town: "Localidade",
        address: "Morada",
        postalCode: "Código Postal",
        schedule: "Horário",
        workingDays: "Dias Úteis",
        saturdays: "Sábados",
        mondayToSaturday: "Segunda a Sábado",
        sundays: "Domingos",
        saturdaysSundaysHolidays: "Sábados, Domingos e Feriados",
        daily: "Diariamente",
        exceptSunday: "Segunda a Sábado",
        saturdaysHolidays: "Sábados e Feriados",
        sundaysMondaysIfHolidays: "Domingos e Segunda (se feriado)",
        contactInfo: "Informações",
        contactCompliments: "Elogios",
        contactComplaints: "Reclamações",
        contactSuggestions: "Sugestões",
        reason: "Motivo",
        contactInfoSchedule: "Horários e frequências das linhas",
        contactInfoDispatches: "Despachos",
        contactInfoDisableTransport: "Transporte de passageiros com cadeira de rodas",
        contactInfoCustomer: "Informação disponibilizada aos passageiros",
        contactInfoOther: "Outros assuntos",
        contactInfoPathsStops: "Percursos e paragens de linhas",
        contactInfoTicket: "Títulos e Tarifas",
        contactInfoLostFound: "Perdidos e achados",
        contactInfoInvoiceRequest: "Pedido Fatura",
        contactComplaintsPassengerRelations: "Atendimento e relacionamento com os passageiros",
        contactComplaintsDelays: "Circulação adiantada/atrasada",
        contactComplaintsNoShows: "Circulações não realizadas",
        contactComplaintsConfort: "Conforto e limpeza dos veículos",
        contactComplaintsOther: "Outros assuntos",
        contactComplaintsRefunds: "Reembolso bilhete",
        contactComplaintsSchedulesFrequencies: "Horários e frequências",
        contactComplaintsUserInformation: "Informação disponibilizada aos utilizadores",
        contactComplaintsBaggage: "Bagagem ou despacho extraviado ou danificado",
        contactComplaintsIncapacityOfTransport: "Incapacidade de transportar todos os utilizadores",
        contactComplaintsTechnicalProblems: "Problemas técnicos",
        refundsPolicy: "Politicas de devolução",
        contactComplaintsTicket: "Títulos e Tarifas",
        contactComplaintsPathStops: "Percursos e paragens de linhas",
        contactComplaintsInvoiceRequest: "Pedido Fatura",
        provideSubject: "Por favor, indique o assunto relativo a este contacto",
        moreInfoSupportline: "Para mais informações contacte a nossa linha de apoio ao cliente 253 009 900 (disponível das 09h às 19h/dias úteis). Chamada para a rede fixa nacional.",
        file: "Anexo",
        fileHelp: "A dimensão dos ficheiros deve ser inferior a 2 MB. Tipos de ficheiro permitidos: jpg jpeg png pdf doc docx."
    }
});

export const changeLanguage = (languageKey) => {
    strings.setLanguage(languageKey);
}
